<template>
  <section class="section main-section">
    <h4 class="section__title section__title_centered mb-1">
      Добавить лицензию
    </h4>
    <GoBackIcon back-route-name="main.licenses" />
    <q-form @submit.prevent="submit" class="q-mt-lg add-license-form">
      <div class="add-license-form-block">
        <q-input
          filled
          v-model="licenseNumber"
          type="number"
          label="Номер лицензии"
        >
        </q-input>

        <q-input
          class="q-mt-sm"
          filled
          v-model="bin"
          type="number"
          label="БИН/ИИН"
        >
        </q-input>

        <q-input
          class="q-mt-sm"
          filled
          v-model="address"
          label="Адрес склада, деятельности"
        >
        </q-input>

        <q-select
          class="q-mt-sm"
          filled
          v-model="dgdId"
          :options="dgdList"
          label="ДГД (Лицензиар)"
          emit-value
          map-options
        />

        <q-select
          class="q-mt-sm"
          filled
          v-model="ugdId"
          :options="filteredUgdList"
          label="Город/район (УГД)"
          emit-value
          map-options
        />

        <q-select
          class="q-mt-sm"
          filled
          v-model="status"
          :options="statusList"
          label="Статус лицензии"
          emit-value
          map-options
        />

        <q-select
          class="q-mt-sm"
          filled
          v-model="source"
          :options="sourceList"
          label="Источник"
          emit-value
          map-options
        />

        <q-select
          class="q-mt-sm q-mb-md"
          filled
          v-model="implementation"
          :options="implementationList"
          label="Вид реализации"
          emit-value
          map-options
        />

        <DatePicker v-model="date" :popover="{ visibility: 'click' }">
          <template v-slot="{ inputValue, togglePopover }">
            <div @click="togglePopover()" class="dates-range">
              <span v-show="!inputValue.start" class="dates-range__text">
                Дата выдачи лицензии
              </span>
              <span v-show="date" class="dates-range__text_selected">
                {{ filterDate(date) }}
                <span @click.stop="clearDate" class="q-ml-auto">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="18px"
                    viewBox="0 0 24 24"
                    width="18px"
                    fill="#000000"
                  >
                    <path d="M0 0h24v24H0V0z" fill="none" />
                    <path
                      d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"
                    />
                  </svg>
                </span>
              </span>
            </div>
          </template>
        </DatePicker>

        <q-input
          class="q-mt-lg"
          filled
          v-model="legalAddress"
          label="Адрес (юридический)"
        >
        </q-input>

        <q-input
          class="q-mt-md"
          filled
          v-model="licenseName"
          label="Наименование лицензиата"
        >
        </q-input>

        <q-btn
          class="q-mt-lg"
          type="submit"
          color="primary"
          no-caps
          :loading="isLoading"
          :disable="isSubmitDisabled"
          label="Добавить"
        />
      </div>
    </q-form>
  </section>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from "vue";
import GoBackIcon from "@/components/icon-components/GoBackIcon.vue";
import { useRouter } from "vue-router";
import { dgdApi } from "@/api/dgd.api";
import { DatePicker } from "v-calendar";
import "v-calendar/dist/style.css";
import { licensesApi } from "@/api/licenses.api";
import { useQuasar } from "quasar";

export default defineComponent({
  name: "AddLicensePage",
  components: {
    DatePicker,
    GoBackIcon,
  },

  setup() {
    const $q = useQuasar();
    const isLoading = ref(false);
    const licenseNumber = ref("");
    const bin = ref("");
    const status = ref("");
    const dgdId = ref("");
    const ugdId = ref("");
    const address = ref("");
    const legalAddress = ref("");
    const licenseName = ref("");
    const source = ref("");
    const implementation = ref("");
    const date = ref("");

    const dgdList = ref([]);
    const ugdList = ref([]);
    const statusList = ref([
      { label: "Недействительный", value: "Недействительный" },
      { label: "Действительный", value: "Действительный" },
    ]);
    const sourceList = ref([
      { label: "КГД", value: "kgd" },
      { label: "Wipon", value: "wipon" },
      { label: "Elicense", value: "elicense" },
    ]);
    const implementationList = ref([
      { label: "Розничная", value: "розничная" },
      { label: "Оптовая", value: "оптовая" },
    ]);

    const filteredUgdList = computed(() => {
      if (!dgdId.value) return [];
      return ugdList.value.filter((el) => el.dgd_id == dgdId.value);
    });

    const isSubmitDisabled = computed(() => {
      return (
        !licenseNumber.value ||
        !bin.value ||
        !address.value ||
        !dgdId.value ||
        !ugdId.value ||
        !status.value ||
        !source.value ||
        !implementation.value ||
        !date.value ||
        !legalAddress.value ||
        !licenseName.value
      );
    });

    const filterDate = (date) => {
      if (!date) return "";
      return new Intl.DateTimeFormat("kz-KZ", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      }).format(date);
    };

    const clearDate = () => {
      date.value = "";
    };

    const fetchDgdList = async () => {
      const { data } = await dgdApi.fetchDgds();
      if (data && Array.isArray(data)) {
        data.forEach((el) => {
          el.label = el.name_ru;
          el.value = el.id;
        });
        dgdList.value = data;
      }
    };

    const fetchUgdList = async () => {
      const { data } = await dgdApi.fetchUgds();
      if (data && Array.isArray(data)) {
        data.forEach((el) => {
          el.label = el.name_ru;
          el.value = el.id;
        });
        ugdList.value = data;
      }
    };

    onMounted(async () => {
      isLoading.value = true;
      try {
        await fetchUgdList();
        await fetchDgdList();
      } finally {
        isLoading.value = false;
      }
    });

    const clearAllInputs = () => {
      licenseNumber.value = "";
      bin.value = "";
      status.value = "";
      dgdId.value = "";
      ugdId.value = "";
      address.value = "";
      legalAddress.value = "";
      licenseName.value = "";
      source.value = "";
      implementation.value = "";
      date.value = "";
    };

    const submit = async () => {
      isLoading.value = true;
      try {
        const payload = {
          licenseNumber: licenseNumber.value,
          bin: bin.value,
          status: status.value,
          dgdId: dgdId.value,
          ugdId: ugdId.value,
          address: address.value,
          legalAddress: legalAddress.value,
          licenseName: licenseName.value,
          source: source.value,
          implementation: implementation.value,
          date: date.value,
        };

        await licensesApi.createNewLicense(payload);
        clearAllInputs();
        $q.notify({
          message: "Лицензия успешно добавлена",
          position: "top-right",
          timeout: 5000,
          icon: "done",
        });
      } finally {
        isLoading.value = false;
      }
    };

    return {
      isLoading,
      licenseNumber,
      bin,
      address,
      legalAddress,
      dgdId,
      ugdId,
      status,
      source,
      implementation,
      licenseName,
      date,
      filteredUgdList,
      dgdList,
      statusList,
      sourceList,
      implementationList,
      isSubmitDisabled,
      clearDate,
      filterDate,
      submit,
    };
  },
});
</script>

<style lang="scss">
.add-license-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &-block {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 350px;
  }
}

.dates-range {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 0.375rem;
  color: grey;
  cursor: pointer;
  margin-top: 5px;

  &__text {
    &_selected {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #000;
    }
  }
}
</style>
